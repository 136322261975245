var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Colors from 'color';
import { FlatList, ImageBackground, ScrollView, TouchableOpacity, View } from 'react-native';
import { Button, MD2Colors, RadioButton, Text, useTheme } from 'react-native-paper';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import StepIndicator from 'react-native-step-indicator';
import moment from 'moment';
import { HtmlView } from './common';
export const IdentityVerification = (props) => {
    var _a, _b, _c;
    const theme = useTheme();
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    useEffect(() => {
        resetForm();
    }, [props.idvPrompt]);
    useEffect(() => {
        setIsFormValid((selectedAnswer === null || selectedAnswer === void 0 ? void 0 : selectedAnswer.length) > 0);
    }, [selectedAnswer]);
    const resetForm = () => {
        setSelectedAnswer('');
        setIsFormValid(false);
    };
    const renderTimeLeftDisplay = (remainingTime) => {
        return (_jsx(Text, { style: {
                textShadowColor: 'black',
                textShadowRadius: 5,
            }, children: new Date(remainingTime * 1000).toISOString().substring(14, 19) }));
    };
    const renderAnswer = (answer) => {
        return (_jsx(TouchableOpacity, { onPress: () => setSelectedAnswer(answer), children: _jsxs(View, { style: { flexDirection: 'row', alignItems: 'center', paddingTop: 20 }, children: [_jsx(RadioButton.Android, { color: theme.colors.primary, value: answer }), _jsx(Text, { style: { paddingLeft: 10 }, children: answer })] }) }));
    };
    const getTimeoutPeriod = () => {
        var _a;
        return moment((_a = props.idvPrompt) === null || _a === void 0 ? void 0 : _a.timeout).diff(moment(), 'seconds') || 300;
    };
    const getTimeOutWarningIntervals = () => {
        return [getTimeoutPeriod() / 2, getTimeoutPeriod() / 4, 0];
    };
    const getTimeoutWarningColors = () => {
        return [Colors(theme.colors.primary).hex(), Colors(theme.colors.secondary).hex(), Colors(theme.colors.tertiary).hex()];
    };
    const onSubmitIdvAnswer = () => __awaiter(void 0, void 0, void 0, function* () {
        if (props.onSubmitAnswer) {
            yield props.onSubmitAnswer(selectedAnswer);
        }
    });
    const onSignout = () => {
        if (props.onSignout) {
            props.onSignout();
        }
    };
    const onFinished = () => {
        if (props.onFinished) {
            props.onFinished();
        }
    };
    const isRequired = props.idvPrompt.status === 'REQUIRED';
    const isSuccessful = props.idvPrompt.status === 'SUCCESS';
    const isBlocked = props.idvPrompt.status === 'BLOCKED';
    const isFailed = props.idvPrompt.status === 'FAILED';
    const currentQuestion = props.idvPrompt.currentQuestion || 0;
    return (_jsxs(ScrollView, { children: [_jsx(View, { children: _jsx(View, { style: { flex: 1, flexDirection: 'row' }, children: _jsxs(ImageBackground, { style: {
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, imageStyle: { opacity: 0.3 }, source: props.backgroundImage, children: [_jsx(HtmlView, { children: (_a = props.idvPrompt) === null || _a === void 0 ? void 0 : _a.displayMessage }), isRequired && (_jsx(CountdownCircleTimer, { strokeWidth: 5, isPlaying: true, isSmoothColorTransition: true, size: 50, trailColor: MD2Colors.grey500, onComplete: onSignout, duration: getTimeoutPeriod(), 
                                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                colorsTime: getTimeOutWarningIntervals(), 
                                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                colors: getTimeoutWarningColors(), children: ({ remainingTime }) => renderTimeLeftDisplay(remainingTime) })), isSuccessful && (_jsx(View, { style: { flex: 1, alignItems: 'center' }, children: _jsx(Button, { mode: 'contained', onPress: onFinished, children: "Let's Go" }) })), isBlocked && (_jsx(View, { style: { flex: 1, alignItems: 'center' }, children: _jsx(Button, { mode: 'contained', onPress: onSignout, children: "Sign Out" }) })), isFailed && (_jsxs(View, { style: { flex: 1, alignItems: 'center' }, children: [_jsx(Button, { mode: 'contained', onPress: onSignout, children: "Try Again Later" }), _jsx(View, { style: { marginTop: 30 }, children: _jsx(CountdownCircleTimer, { strokeWidth: 5, isPlaying: true, isSmoothColorTransition: true, size: 50, trailColor: MD2Colors.grey500, onComplete: onSignout, duration: getTimeoutPeriod(), 
                                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            colorsTime: getTimeOutWarningIntervals(), 
                                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            //@ts-ignore
                                            colors: getTimeoutWarningColors(), children: ({ remainingTime }) => renderTimeLeftDisplay(remainingTime) }) })] }))] }) }) }), isRequired && (_jsxs(View, { children: [_jsx(View, { style: { flex: 1, marginTop: 20 }, children: _jsx(StepIndicator, { stepCount: props.idvPrompt.totalQuestionCount, currentPosition: currentQuestion - 1, customStyles: {
                                separatorFinishedColor: theme.colors.primary,
                                separatorUnFinishedColor: theme.colors.secondary,
                                stepIndicatorFinishedColor: theme.colors.primary,
                                stepIndicatorLabelFinishedColor: theme.colors.onPrimary,
                                stepIndicatorLabelUnFinishedColor: theme.colors.onSecondary,
                                stepIndicatorUnFinishedColor: theme.colors.secondary,
                                stepIndicatorCurrentColor: theme.colors.tertiary,
                                stepIndicatorLabelCurrentColor: theme.colors.onTertiary,
                                stepStrokeCurrentColor: theme.colors.primary,
                                currentStepIndicatorSize: 50,
                                currentStepIndicatorLabelFontSize: 20,
                                stepIndicatorSize: 30,
                            } }) }), _jsxs(View, { style: { alignItems: 'center', marginHorizontal: 10 }, children: [_jsx(Text, { style: { fontSize: 20 }, children: (_b = props.idvPrompt) === null || _b === void 0 ? void 0 : _b.question }), _jsx(View, { style: { margin: 20 }, children: _jsx(RadioButton.Group, { onValueChange: (newAnswer) => setSelectedAnswer(newAnswer), value: selectedAnswer, children: _jsx(FlatList, { style: { flexGrow: 0 }, data: (_c = props.idvPrompt) === null || _c === void 0 ? void 0 : _c.answers, renderItem: (answer) => renderAnswer(answer.item) }) }) })] }), _jsx(View, { style: { alignItems: 'center' }, children: _jsx(Button, { mode: 'contained', disabled: !isFormValid, onPress: onSubmitIdvAnswer, children: "Submit" }) })] }))] }));
};
