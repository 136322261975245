import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FlatList, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';
import { FaqQuestion } from './FaqQuestion';
export const FaqCategory = (props) => {
    const styles = StyleSheet.create({
        categoryContainer: {},
        categoryTitleStyle: {
            paddingVertical: 10,
            paddingLeft: 15,
            fontSize: 24,
        },
    });
    const renderFaq = (faq) => {
        return (_jsx(FaqQuestion, { id: faq.item.id, question: faq.item.question, answer: faq.item.answer, questionTitleStyle: props.questionTitleStyle, questionCollapsedStyle: props.questionCollapsedStyle, questionExpandedStyle: props.questionExpandedStyle, answerTitleStyle: props.answerTitleStyle }));
    };
    return (_jsxs(View, { style: [styles.categoryContainer, props.style], children: [props.name ? _jsx(Title, { style: [styles.categoryTitleStyle, props.categoryTitleStyle], children: props.name }) : _jsx(_Fragment, {}), _jsx(FlatList, { data: props.questions, renderItem: renderFaq })] }));
};
