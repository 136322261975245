// Hexadecimal color code for transparency: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export var Opacity;
(function (Opacity) {
    Opacity["PERCENT_100"] = "FF";
    Opacity["PERCENT_99"] = "FC";
    Opacity["PERCENT_98"] = "FA";
    Opacity["PERCENT_97"] = "F7";
    Opacity["PERCENT_96"] = "F5";
    Opacity["PERCENT_95"] = "F2";
    Opacity["PERCENT_94"] = "F0";
    Opacity["PERCENT_93"] = "ED";
    Opacity["PERCENT_92"] = "EB";
    Opacity["PERCENT_91"] = "E8";
    Opacity["PERCENT_90"] = "E6";
    Opacity["PERCENT_89"] = "E3";
    Opacity["PERCENT_88"] = "E0";
    Opacity["PERCENT_87"] = "DE";
    Opacity["PERCENT_86"] = "DB";
    Opacity["PERCENT_85"] = "D9";
    Opacity["PERCENT_84"] = "D6";
    Opacity["PERCENT_83"] = "D4";
    Opacity["PERCENT_82"] = "D1";
    Opacity["PERCENT_81"] = "CF";
    Opacity["PERCENT_80"] = "CC";
    Opacity["PERCENT_79"] = "C9";
    Opacity["PERCENT_78"] = "C7";
    Opacity["PERCENT_77"] = "C4";
    Opacity["PERCENT_76"] = "C2";
    Opacity["PERCENT_75"] = "BF";
    Opacity["PERCENT_74"] = "BD";
    Opacity["PERCENT_73"] = "BA";
    Opacity["PERCENT_72"] = "B8";
    Opacity["PERCENT_71"] = "B5";
    Opacity["PERCENT_70"] = "B3";
    Opacity["PERCENT_69"] = "B0";
    Opacity["PERCENT_68"] = "AD";
    Opacity["PERCENT_67"] = "AB";
    Opacity["PERCENT_66"] = "A8";
    Opacity["PERCENT_65"] = "A6";
    Opacity["PERCENT_64"] = "A3";
    Opacity["PERCENT_63"] = "A1";
    Opacity["PERCENT_62"] = "9E";
    Opacity["PERCENT_61"] = "9C";
    Opacity["PERCENT_60"] = "99";
    Opacity["PERCENT_59"] = "96";
    Opacity["PERCENT_58"] = "94";
    Opacity["PERCENT_57"] = "91";
    Opacity["PERCENT_56"] = "8F";
    Opacity["PERCENT_55"] = "8C";
    Opacity["PERCENT_54"] = "8A";
    Opacity["PERCENT_53"] = "87";
    Opacity["PERCENT_52"] = "85";
    Opacity["PERCENT_51"] = "82";
    Opacity["PERCENT_50"] = "80";
    Opacity["PERCENT_49"] = "7D";
    Opacity["PERCENT_48"] = "7A";
    Opacity["PERCENT_47"] = "78";
    Opacity["PERCENT_46"] = "75";
    Opacity["PERCENT_45"] = "73";
    Opacity["PERCENT_44"] = "70";
    Opacity["PERCENT_43"] = "6E";
    Opacity["PERCENT_42"] = "6B";
    Opacity["PERCENT_41"] = "69";
    Opacity["PERCENT_40"] = "66";
    Opacity["PERCENT_39"] = "63";
    Opacity["PERCENT_38"] = "61";
    Opacity["PERCENT_37"] = "5E";
    Opacity["PERCENT_36"] = "5C";
    Opacity["PERCENT_35"] = "59";
    Opacity["PERCENT_34"] = "57";
    Opacity["PERCENT_33"] = "54";
    Opacity["PERCENT_32"] = "52";
    Opacity["PERCENT_31"] = "4F";
    Opacity["PERCENT_30"] = "4D";
    Opacity["PERCENT_29"] = "4A";
    Opacity["PERCENT_28"] = "47";
    Opacity["PERCENT_27"] = "45";
    Opacity["PERCENT_26"] = "42";
    Opacity["PERCENT_25"] = "40";
    Opacity["PERCENT_24"] = "3D";
    Opacity["PERCENT_23"] = "3B";
    Opacity["PERCENT_22"] = "38";
    Opacity["PERCENT_21"] = "36";
    Opacity["PERCENT_20"] = "33";
    Opacity["PERCENT_19"] = "30";
    Opacity["PERCENT_18"] = "2E";
    Opacity["PERCENT_17"] = "2B";
    Opacity["PERCENT_16"] = "29";
    Opacity["PERCENT_15"] = "26";
    Opacity["PERCENT_14"] = "24";
    Opacity["PERCENT_13"] = "21";
    Opacity["PERCENT_12"] = "1F";
    Opacity["PERCENT_11"] = "1C";
    Opacity["PERCENT_10"] = "1A";
    Opacity["PERCENT_9"] = "17";
    Opacity["PERCENT_8"] = "14";
    Opacity["PERCENT_7"] = "12";
    Opacity["PERCENT_6"] = "0F";
    Opacity["PERCENT_5"] = "0D";
    Opacity["PERCENT_4"] = "0A";
    Opacity["PERCENT_3"] = "08";
    Opacity["PERCENT_2"] = "05";
    Opacity["PERCENT_1"] = "03";
    Opacity["PERCENT_0"] = "00";
})(Opacity || (Opacity = {}));
export class OpacityUtilities {
}
OpacityUtilities.getOpacityForColor = (hexColor, opacity) => {
    return `${hexColor}${opacity}`;
};
