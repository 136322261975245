import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
export const Strong = (props) => {
    const styles = StyleSheet.create({
        textStyle: {
            fontWeight: 'bold',
        },
    });
    return (_jsx(Text, Object.assign({ style: [styles.textStyle, props.style] }, props, { children: props.children })));
};
