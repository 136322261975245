import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet } from "react-native";
import { Text } from "react-native-paper";
export const EmptyState = (props) => {
    const styles = StyleSheet.create({
        container: {
            alignItems: "center",
            justifyContent: "center",
            display: 'flex',
        },
    });
    return _jsx(Text, { style: [styles.container], children: props.text });
};
