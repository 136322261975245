import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { PhoneType } from '@luminate/luminate-ts-sdk';
export const RegistrationContext = createContext({});
export const RegistrationProvider = (props) => {
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const [email, setEmail] = useState();
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [guardianRequest, setGuardianRequest] = useState(false);
    const [guardianFirstName, setGuardianFirstName] = useState();
    const [guardianLastName, setGuardianLastName] = useState();
    const [guardianDateOfBirth, setGuardianDateOfBirth] = useState();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [gender, setGender] = useState();
    const [last4, setLast4] = useState();
    const [phoneType, setPhoneType] = useState(PhoneType.MOBILE);
    const [phone, setPhone] = useState();
    const [addressOne, setAddressOne] = useState();
    const [addressTwo, setAddressTwo] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zip, setZip] = useState();
    const reset = () => {
        setFirstName(undefined);
        setMiddleName(undefined);
        setLastName(undefined);
        setDateOfBirth(undefined);
        setEmail(undefined);
        setUserName(undefined);
        setPassword(undefined);
        setGuardianRequest(false);
        setGuardianFirstName(undefined);
        setGuardianLastName(undefined);
        setGuardianDateOfBirth(undefined);
        setAgreeToTerms(false);
        setGender(undefined);
        setLast4(undefined);
        setPhoneType(PhoneType.MOBILE);
        setPhone(undefined);
        setAddressOne(undefined);
        setAddressTwo(undefined);
        setCity(undefined);
        setState(undefined);
        setZip(undefined);
    };
    return (_jsx(RegistrationContext.Provider, { value: {
            firstName,
            setFirstName,
            middleName,
            setMiddleName,
            lastName,
            setLastName,
            dateOfBirth,
            setDateOfBirth,
            email,
            setEmail,
            password,
            setPassword,
            userName,
            setUserName,
            guardianRequest,
            setGuardianRequest,
            guardianFirstName,
            setGuardianFirstName,
            guardianLastName,
            setGuardianLastName,
            guardianDateOfBirth,
            setGuardianDateOfBirth,
            agreeToTerms,
            setAgreeToTerms,
            gender,
            setGender,
            last4,
            setLast4,
            phoneType,
            setPhoneType,
            phone,
            setPhone,
            addressOne,
            setAddressOne,
            addressTwo,
            setAddressTwo,
            city,
            setCity,
            state,
            setState,
            zip,
            setZip,
            reset,
        }, children: props.children }));
};
export const useRegistrationContext = () => {
    const context = useContext(RegistrationContext);
    if (!context) {
        throw new Error('useRegistrationContext must be used with RegistrationProvider');
    }
    return context;
};
