import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, IconButton, Text, useTheme } from 'react-native-paper';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
export const ResultSummary = (props) => {
    const theme = useTheme();
    const onViewDetailsPressed = () => {
        props.onSelect({
            id: props.id,
            read: props.read,
            date: props.date,
            provider: props.provider,
            accession: props.accession,
        });
    };
    return (_jsxs(Card, { style: props.containerStyle, children: [_jsx(Card.Title, { title: props.date, left: () => props.showUnread && !props.read && _jsx(MaterialIcons, { name: 'circle', color: theme.colors.primary }), right: () => _jsx(IconButton, { icon: 'chevron-right', onPress: onViewDetailsPressed }) }), _jsxs(Card.Content, { children: [_jsx(Text, { children: props.provider }), _jsxs(Text, { children: ["Accession#: ", props.accession] })] })] }));
};
