import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
export var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["DESKTOP"] = 0] = "DESKTOP";
    DeviceType[DeviceType["TABLET"] = 1] = "TABLET";
    DeviceType[DeviceType["MOBILE"] = 2] = "MOBILE";
})(DeviceType || (DeviceType = {}));
export const useDeviceType = () => {
    const { width } = useWindowDimensions();
    const getDeviceType = () => {
        const isTablet = width < 1024 && width > 540;
        const isMobile = width <= 540;
        return isMobile ? DeviceType.MOBILE : isTablet ? DeviceType.TABLET : DeviceType.DESKTOP;
    };
    const [deviceType, setDeviceType] = useState(getDeviceType());
    useEffect(() => {
        setDeviceType(getDeviceType());
    }, [width]);
    return {
        deviceType,
    };
};
