import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Color from 'color';
import { useTheme } from 'react-native-paper';
import { PlatformUtils } from '../services';
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';
export const HtmlView = (props) => {
    const theme = useTheme();
    const defaultStyle = {
        boxSizing: 'border-box',
        direction: 'ltr',
        display: 'block',
        fontSize: 15,
        fontFamily: theme.fonts.bodyMedium.fontFamily,
        fontWeight: theme.fonts.bodyMedium.fontWeight,
        color: Color(theme.colors.onBackground),
        letterSpacing: 0.15,
        overflowWrap: 'break-word',
        pointerEvents: 'auto',
        textAlign: 'left',
        textRendering: 'optimizeLegibility',
        textSizeAdjust: '100%',
        unicodeBidi: 'isolate',
        whiteSpace: 'pre-wrap',
        lineHeight: '20px',
        width: '100%',
        height: '100%',
    };
    const { width } = useWindowDimensions();
    return (_jsxs(_Fragment, { children: [PlatformUtils.isWeb() && _jsx("div", { style: Object.assign(Object.assign({}, defaultStyle), props.style), dangerouslySetInnerHTML: { __html: props === null || props === void 0 ? void 0 : props.children } }), !PlatformUtils.isWeb() && props.children && (_jsx(RenderHtml, { contentWidth: width, source: { html: props === null || props === void 0 ? void 0 : props.children }, baseStyle: Object.assign({}, props.style) }))] }));
};
