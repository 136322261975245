var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { HelperText, Text, useTheme } from 'react-native-paper';
import { View } from 'react-native';
import { mustBeValidMonth, mustBeValidDate, mustPastOrPresentYear, mustBeValidYear, mustBeValidDay } from '@luminate/luminate-ts-sdk';
import { ValidatedInput } from './ValidatedInput';
export const ValidatedDateInput = (props) => {
    var _a, _b;
    const theme = useTheme();
    const [month, setMonth] = useState();
    const [day, setDay] = useState();
    const [year, setYear] = useState();
    const [isValidMonth, setIsValidMonth] = useState(false);
    const [monthErrors, setMonthErrors] = useState([]);
    const [monthFocus, setMonthFocus] = useState(false);
    const [isValidDay, setIsValidDay] = useState(false);
    const [dayErrors, setDayErrors] = useState([]);
    const [dayFocus, setDayFocus] = useState(false);
    const [isValidYear, setIsValidYear] = useState(false);
    const [yearErrors, setYearErrors] = useState([]);
    const [yearFocus, setYearFocus] = useState(false);
    const hasError = (((_a = props.errors) === null || _a === void 0 ? void 0 : _a.length) || 0) > 0;
    const [hasFocus, setHasFocus] = useState(false);
    useEffect(() => {
        const proposedDate = Date.parse(props.value || '');
        if (!Number.isNaN(proposedDate)) {
            const dateValue = new Date(proposedDate);
            const daMonth = (dateValue.getMonth() + 1).toString().padStart(2, '0');
            const daDay = dateValue.getDate().toString().padStart(2, '0');
            const daYear = dateValue.getFullYear().toString();
            setMonth(daMonth);
            setDay(daDay);
            setYear(daYear);
        }
    }, [props.value]);
    useEffect(() => {
        onValidate();
    }, [monthErrors, dayErrors, yearErrors]);
    useEffect(() => {
        setHasFocus(monthFocus || dayFocus || yearFocus);
    }, [monthFocus, dayFocus, yearFocus]);
    const onValidateMonth = (errors) => {
        setIsValidMonth(errors.length === 0);
        setMonthErrors(errors);
    };
    const onValidateDay = (errors) => {
        setIsValidDay(errors.length === 0);
        setDayErrors(errors);
    };
    const onValidateYear = (errors) => {
        setIsValidYear(errors.length === 0);
        setYearErrors(errors);
    };
    const allRules = new Array(...(props.rules || []), ...[mustBeValidDate]);
    const onMonthChangeText = (value) => {
        setMonth(value);
    };
    const onDayChangeText = (value) => {
        setDay(value);
    };
    const onYearChangeText = (value) => {
        setYear(value);
    };
    const onValidate = () => {
        const dobValidationErrors = new Array();
        if (!isValidMonth) {
            dobValidationErrors.push(...monthErrors);
        }
        if (!isValidDay) {
            dobValidationErrors.push(...dayErrors);
        }
        if (!isValidYear) {
            dobValidationErrors.push(...yearErrors);
        }
        if (isValidMonth && isValidDay && isValidYear) {
            const fullDate = `${month}/${day}/${year}`;
            allRules.forEach((validator) => {
                const error = validator(fullDate);
                if (error) {
                    dobValidationErrors.push(error);
                }
            });
            if (dobValidationErrors.length === 0 && props.onChangeText) {
                props.onChangeText(fullDate);
            }
        }
        if (props.onValidate) {
            props.onValidate(dobValidationErrors);
        }
    };
    const getHighlightColor = () => {
        return hasError ? theme.colors.error : hasFocus ? theme.colors.primary : theme.colors.outline;
    };
    const getHighlightWidth = () => {
        return hasError || hasFocus ? 2 : 1;
    };
    const onFocus = (event) => {
        if (props.onFocus) {
            props.onFocus(event);
        }
    };
    const onBlur = (event) => {
        if (props.onBlur) {
            props.onBlur(event);
        }
    };
    const onMonthFocus = (event) => {
        setMonthFocus(true);
        onFocus(event);
    };
    const onDayFocus = (event) => {
        setDayFocus(true);
        onFocus(event);
    };
    const onYearFocus = (event) => {
        setYearFocus(true);
        onFocus(event);
    };
    const onMonthBlur = (event) => {
        setMonthFocus(false);
        onBlur(event);
    };
    const onDayBlur = (event) => {
        setDayFocus(false);
        onBlur(event);
    };
    const onYearBlur = (event) => {
        setYearFocus(false);
        onBlur(event);
    };
    const { testID } = props, rest = __rest(props, ["testID"]);
    return (_jsxs(View, { style: { marginTop: 5 }, children: [_jsxs(View, { testID: testID, style: {
                    flex: 1,
                    marginTop: 5,
                    borderStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: getHighlightColor(),
                    borderLeftWidth: getHighlightWidth(),
                    borderLeftColor: getHighlightColor(),
                    borderTopWidth: getHighlightWidth(),
                    borderTopColor: getHighlightColor(),
                    borderRightWidth: getHighlightWidth(),
                    borderRightColor: getHighlightColor(),
                    borderRadius: 3,
                }, children: [_jsx(Text, { style: {
                            position: 'absolute',
                            paddingHorizontal: 6,
                            top: -9,
                            left: 6,
                            zIndex: 1,
                            backgroundColor: theme.colors.background,
                            color: getHighlightColor(),
                        }, variant: 'bodySmall', children: props.label }), _jsxs(View, { style: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }, children: [_jsx(ValidatedInput, Object.assign({ testID: `${testID}-month` }, rest, { mode: 'flat', value: month, style: { flex: 1, backgroundColor: theme.colors.background }, label: 'Month', placeholder: 'MM', keyboardType: 'decimal-pad', maxLength: 2, rules: [mustBeValidMonth], errors: monthErrors, hideErrors: true, onValidate: onValidateMonth, onChangeText: onMonthChangeText, onFocus: onMonthFocus, onBlur: onMonthBlur })), _jsx(ValidatedInput, Object.assign({ testID: `${testID}-day` }, rest, { mode: 'flat', value: day, style: { flex: 1, backgroundColor: theme.colors.background }, label: 'Day', placeholder: 'DD', keyboardType: 'decimal-pad', maxLength: 2, rules: [mustBeValidDay], errors: dayErrors, hideErrors: true, onValidate: onValidateDay, onChangeText: onDayChangeText, onFocus: onDayFocus, onBlur: onDayBlur })), _jsx(ValidatedInput, Object.assign({ testID: `${testID}-year` }, rest, { mode: 'flat', value: year, style: { flex: 1, backgroundColor: theme.colors.background }, label: 'Year', placeholder: 'YYYY', keyboardType: 'decimal-pad', maxLength: 4, rules: [mustBeValidYear, mustPastOrPresentYear], errors: yearErrors, hideErrors: true, onValidate: onValidateYear, onChangeText: onYearChangeText, onFocus: onYearFocus, onBlur: onYearBlur }))] })] }), (_b = props.errors) === null || _b === void 0 ? void 0 : _b.map((error, index) => (_jsx(HelperText, { testID: `${props.testID}_errors`, type: 'error', style: props.errorsStyle, children: error }, `error_${index}`)))] }));
};
