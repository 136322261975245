var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HelperText, Text } from 'react-native-paper';
import { SessionService } from '@luminate/luminate-ts-sdk';
import { EmailInput, Strong } from './common';
export const ForgotUserName = (props) => {
    const [email, setEmail] = useState();
    const [emailErrors, setEmailErrors] = useState([]);
    const [emailValid, setEmailValid] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const showHeader = props.showHeader || true;
    const onEmailChanged = (value) => {
        setEmail(value);
    };
    const onEmailValidated = (errors) => {
        setEmailErrors(errors);
        setEmailValid(errors.length === 0);
    };
    const isFormValid = emailValid;
    const styles = StyleSheet.create({
        spacing: {
            paddingVertical: 5,
        },
        actionsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 20,
        },
    });
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setError(false);
            yield SessionService.create(props.apiUrl).forgotUsername(email);
            setSuccess(true);
        }
        catch (ex) {
            setError(true);
        }
    });
    return (_jsx(View, { style: props.style, children: !success ? (_jsxs(_Fragment, { children: [showHeader ? (_jsx(Text, { variant: 'headlineMedium', style: [styles.spacing, props.headerStyle], children: "Forgot Username" })) : (_jsx(_Fragment, {})), _jsx(Text, { style: [styles.spacing, props.promptStyle], children: "Enter the e-mail for your account below and we'll send you your username." }), _jsx(EmailInput, { label: 'Email', value: email, errors: emailErrors, onChangeText: onEmailChanged, onValidate: onEmailValidated }), error ? (_jsx(_Fragment, { children: _jsx(HelperText, { type: 'error', children: "An error occurred when attempting to retrieve your username. Please try again later." }) })) : (_jsx(_Fragment, {})), _jsxs(View, { style: styles.actionsContainer, children: [_jsx(Button, { mode: 'contained', disabled: !isFormValid, onPress: onSubmit, children: "Submit" }), _jsx(Button, { mode: 'contained-tonal', onPress: props.onClose, children: "Cancel" })] })] })) : (_jsxs(_Fragment, { children: [_jsx(Text, { variant: 'headlineMedium', children: "Check Your Email" }), _jsx(Text, { style: styles.spacing, children: "If our database contains a record that matches the information you provided we will send you an email to the address we have on file." }), _jsxs(Text, { style: styles.spacing, children: ["Please check your email and look for a message with the subject ", _jsx(Strong, { children: "Username Reminder" }), ", it will contain your username."] }), _jsx(Text, { style: styles.spacing, children: "If you do not retrieve an email, you may not have signed up for an account yet." }), _jsx(Button, { mode: 'contained', onPress: props.onClose, style: { margin: 20 }, children: "Close" })] })) }));
};
